import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {MyBooking} from './my-booking';
import {BookingResolver} from './booking-resolver';

@Injectable({
  providedIn: 'root'
})
export class BookingLoggingResolver extends BookingResolver {

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MyBooking> {
    console.log('route2', route);
    console.log('state2', state);
    return this.doGet(route, true);
  }
}
