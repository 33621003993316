import { Component, Input } from '@angular/core';

@Component({
  selector: 'collapsible-box',
  template: `
    <h3 class="section-heading" [innerHtml]="heading">test</h3>
    <div
      class="description section-body clickable"
      *ngIf="!open"
      (click)="openBox()"
    >
      <div><a>{{ description }}</a></div>
      <img src="assets/arrow-right.svg" />
    </div>
    <div class="content section-body" *ngIf="open">
      <img class="accent" src="assets/arrow-down.svg" (click)="closeBox()" />
      <ng-content></ng-content>
    </div>
  `,
})
export class CollapsibleBox {
  @Input()
  public heading: string = '';
  @Input()
  public description: string = '';
  @Input()
  public open: boolean = false;

  openBox() {
    this.open = true;
  }

  closeBox() {
    this.open = false;
  }
}
