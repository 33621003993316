import { Package } from './package';
import { Service } from './service';

export class ServicesTotal {
  constructor(private packages: Package[], private services: Service[]) {}

  get total(): number {
    return this.sum(this.packages) + this.sum(this.services);
  }

  private sum(items: { effectivePrice: number }[]): number {
    return items
      .map((i) => i.effectivePrice)
      .reduce((sum, price) => (sum += price), 0);
  }
}
