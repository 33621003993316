<form [formGroup]="form">
  <div class="form-group" [class.required]="isFieldInvalidRequired()">
    <label [for]="ctrlName">
      {{label | translate }}</label>

    <input *ngIf="!mask" type="text" class="form-control" [id]="ctrlName"
           [formControlName]="ctrlName" [class.invalid]="isFieldInvalid()">

    <input *ngIf="mask" type="text" class="form-control" [id]="ctrlName"
           [textMask]="mask"
           [formControlName]="ctrlName" [class.invalid]="isFieldInvalid()">
  </div>
</form>
