import {Injectable} from '@angular/core';
import {Booking} from './booking';
import {MyBooking} from './my-booking';

@Injectable({
  providedIn: 'root'
})
export class BookingStoreService {
  private booking: Booking | null = null;
  private near: Booking[] = [];
  private ical: string | null = null;

  constructor() {
  }

  public set(myBooking: MyBooking | null): void {
    this.booking = myBooking != null ? new Booking(myBooking.booking) : null;
    this.near = myBooking != null ? myBooking.near.map(booking => new Booking(booking)) : [];
    this.ical = myBooking != null ? myBooking.ical : null;
  }

  public get(): Booking | null {
    return this.booking;
  }

  public getNear(): Booking[] {
    return this.near;
  }

  public getIcal(): string | null {
    return this.ical;
  }
}
