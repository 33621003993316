import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {InputMaskReturn} from '../../../core/services/input-mask.service';

@Component({
  selector: 'form-input',
  templateUrl: './form-input.component.html'
})
export class FormInputComponent implements OnInit {
  @Input() label = '';
  @Input() ctrlName!: string;
  @Input() form!: FormGroup;
  @Input() id = '';

  @Input() mask!: InputMaskReturn | null;

  constructor() {
  }

  ngOnInit(): void {
  }

  isFieldInvalid(): boolean {
    if (this.form == null || this.ctrlName == null) {
      return false;
    }
    const ctrl = this.form.get(this.ctrlName);
    return ctrl != null ? ctrl.invalid && ctrl.touched : false;
  }

  isFieldInvalidRequired(): boolean {
    if (this.form == null || this.ctrlName == null) {
      return false;
    }
    const ctrl = this.form.get(this.ctrlName);
    return ctrl != null ? ctrl.invalid && ctrl.touched && ctrl.errors && ctrl.errors.required : false;
  }
}
