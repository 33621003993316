import { Component, Input } from '@angular/core';

@Component({
  selector: 'highlighted-action',
  template: `
    <h3 [innerHtml]="title | translate"></h3>
    <button [innerHtml]="buttonText | translate"></button>
  `,
  host: { 'class': 'section-heading'}
})
export class HighlightedActionComponent {
    @Input()
    title: string = 'Title';
    @Input()
    buttonText: string = 'Button';
}
