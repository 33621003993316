import {Emitter} from './emitter';

export class Service {
  private emitter = new Emitter<Service>();
  private _selected = false;
  editable = true;

  constructor(readonly key: string, readonly price: number, private _regularPrice: number) {
  }

  set selected(state: boolean) {
    if (this.editable) {
      this._selected = state;

      this.emitter.emit(this);
    }
  }

  get selected(): boolean {
    return this._selected;
  }

  toggle() {
    this.selected = !this.selected;
  }

  get effectivePrice(): number {
    return this.selected ? this.price : 0;
  }

  get regularPrice(): number {
    if (this._regularPrice) {
      return this._regularPrice;
    }

    return this.price;
  }

  translationKey(suffix?: string) {
    if (suffix) {
      return `SERVICES.${this.key}_${suffix}`;
    }

    return `SERVICES.${this.key}`;
  }

  observe(callback: { (pkg: Service): void }): void {
    this.emitter.observe(callback);
  }
}
