import {Injectable} from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {Observable, of} from 'rxjs';
import {BookingDataLoaderService} from './booking-data-loader.service';
import {BookingDataBody} from './booking-data-body';

@Injectable({
  providedIn: 'root'
})
export class BookingDataResolver implements Resolve<BookingDataBody> {

  constructor(private loader: BookingDataLoaderService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<BookingDataBody> {
    const key = route.params.key;
    console.log(`Loading data for booking with key ${key}`);
    return this.loader.load(key);
  }
}
