import {Injectable} from '@angular/core';
import {ConfigurationService} from 'src/app/configuration_old/configuration.service';
import {Booking} from 'src/app/domain/booking/booking';
import {Package} from './model/package';
import {Service} from './model/service';
import {ServicesTotal} from './model/services-total';
import {Campaign} from '../../../../../domain/campaign/campaign';

@Injectable()
export class ServiceService {
  constructor(private configurationService: ConfigurationService) {
  }

  async offered(booking: Booking, campaign: Campaign | null): Promise<{
    packages: Package[];
    services: Service[];
    total: ServicesTotal;
  }> {
    const config = await this.configurationService.services();

    if (!booking.hasCourtesyVehicle()) {

    }

    const services = config.services.map(
      ({key, price, regularPrice}) => new Service(key, price, regularPrice)
    ).filter(s => {
      return s.key !== 'COURTESY_VEHICLE_INSURANCE' || booking.hasCourtesyVehicle();
    });

    const packages = config.packages.map(({key, price, services}) => {
      const mapped = services.map(({key, price, regularPrice}) => new Service(key, price, regularPrice));

      return new Package(key, price, mapped);
    }).filter(pkg => {
      return booking.hasCourtesyVehicle() || !pkg.includedServices.find(s => s.key === 'COURTESY_VEHICLE_INSURANCE');
    });

    return {
      packages,
      services,
      total: new ServicesTotal(packages, services),
    };
  }
}
