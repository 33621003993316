import { Component, HostBinding, HostListener, Input } from '@angular/core';

@Component({
  selector: 'rb-checkbox',
  template: `<img src="/assets/Checkbox-isolated-white.svg" />`,
})
export class CheckboxComponent {
  @HostBinding('class.checked')
  @Input()
  checked: boolean = false;
}
