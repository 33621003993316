import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ServicesSectionComponent } from './sections/services-section.component';
import { SharedModule } from '../../../shared/shared.module';
import { CommonModule } from '@angular/common';
import { MyBookingPageComponent } from './my-booking-page.component';
import { PackageCardComponent } from './sections/services/package-card.component';
import { ServiceSelectionComponent } from './sections/services/service-selection.component';
import { ServiceService } from './sections/services/service.service';
import { OldConfigurationModule } from 'src/app/configuration_old/old-configuration.module';
import { HighlightedActionComponent } from './components/highlighted-action.component';
import { HighlightedHeadingComponent } from './components/highlighted-heading.component';
import { InfoBoxComponent } from './sections/mybooking-info-box.component';
import { RelatedBookingsSectionComponent } from './sections/related-bookings/related-bookings.component';
import { SummaryComponent } from './sections/summary/summary.component';

@NgModule({
  imports: [
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    OldConfigurationModule,
  ],
  providers: [ServiceService],
  declarations: [
    MyBookingPageComponent,
    ServicesSectionComponent,
    RelatedBookingsSectionComponent,
    PackageCardComponent,
    ServiceSelectionComponent,
    HighlightedActionComponent,
    HighlightedHeadingComponent,
    InfoBoxComponent,
    SummaryComponent,
  ],
})
export class MyBookingPageModule {}
