<div class="container" data-cy="workshop_details_page">
  <page-title text="WORKSHOP_DETAILS_SHORT" (backChange)="back()"></page-title>
  <div *ngIf="booking">
    <div class="row">
      <div class="col-xs-12">
        <div>{{'BOOKED_WORKSHOP' | translate}}
          <a>{{booking.workshop.name | translate}}</a>
        </div>
      </div>
      <div class="col-xs-12 mt-20">
        <p>{{booking.workshop.description}}</p>
        <p>{{'WORKSHOP_CONTACT' | translate: {phone: booking.workshop.phone} }}</p>
      </div>
    </div>
  </div>
</div>
<div class="pb-20">
  <google-maps [booking]="booking"></google-maps>
</div>
