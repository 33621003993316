import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ButtonRadioGroupOption} from '../controls/button-radio-group/button-radio-group.component';
import {ServicesService} from '../../domain/services/services.service';
import {BaseServiceConfigurationService} from '../../domain/services/base-service-configuration.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'courtesy-vehicle-extra',
  templateUrl: './courtesy-vehicle-extra.component.html'
})
export class CourtesyVehicleExtraComponent implements OnInit {

  carOptions: ButtonRadioGroupOption[] = [];

  @Input()
  chooseExtra!: FormControl;

  constructor(private services: BaseServiceConfigurationService, private translations: TranslateService) {
  }

  get cvInsuranceDeductibleEliminationPrice(): number {
    const cvInsuranceConf = this.services.serviceConfiguration.services.find(s => s.key === 'COURTESY_VEHICLE_INSURANCE');

    return cvInsuranceConf?.price || 149;
  }

  get cvInsuranceDeductible(): number {
    return this.services.cvInsuranceDeductible;
  }

  ngOnInit(): void {
    this.carOptions = [
      {
        preText: 'AGREEMENT_SIGN_CHOOSE_EXTRA_OK_CAR',
        text: this.translations.instant('AGREEMENT_SIGN_CHOOSE_EXTRA_OK_CAR_DESC', {price: this.cvInsuranceDeductibleEliminationPrice}),
        value: true
      },
      {
        preText: 'AGREEMENT_SIGN_CHOOSE_EXTRA_CANCEL_CAR',
        text: this.translations.instant('AGREEMENT_SIGN_CHOOSE_EXTRA_CANCEL_CAR_DESC', {deductible: this.cvInsuranceDeductible}),
        value: false
      }
    ];
  }

}
