<div class="container" data-cy="rebook_or_change_page">
  <page-title text="BOOKING.REBOOK.TITLE" (backChange)="back()"></page-title>
  <div *ngIf="booking">
    <div class="row" *featureRebooking>
      <div class="col-xs-12" *ngIf="booking.isStonechip">
        <div class="font-bold">{{ "BOOKING.REBOOK.LINK.TITLE" | translate }}</div>
        <div>{{ "BOOKING.REBOOK.LINK.DESCRIPTION" | translate }}</div>
        <div class="mt-20"><a [rebook]="booking.key">{{ "BOOKING.REBOOK.LINK.LINK_TEXT" | translate }}</a></div>
      </div>
    </div>
    <div class="row mt-20">
      <div class="col-xs-12">
        <div class="font-bold" *featureRebooking>{{"BOOKING.REBOOK.CHANGES.TITLE" | translate}}</div>
        <div class="font-bold" *featureRebookingDisabled>{{"BOOKING.REBOOK.CHANGES.TITLE_OLD" | translate}}</div>
        <div>{{"BOOKING.REBOOK.CHANGES.DESCRIPTION" | translate}} {{"QUESTIONS" | translate}}</div>
        <div class="form-group mt-20" [class.required]="isFieldInvalid()">
          <label for="message">{{'MESSAGE' | translate}}</label>
          <textarea class="form-control" rows="3" id="message" [class.invalid]="isFieldInvalid()"
                    [formControl]="message"></textarea>
        </div>
        <div class="form-group">
          <input placeholder="{{'INPUT_EMAIL' | translate}}" type="text" class="form-control" [formControl]="email">
        </div>
      </div>
    </div>
    <div class="row mt-20">
      <div class="col-xs-12">
        <button type="button" class="btn btn-medium" (click)="send()">
          {{"SEND" | translate}}
          <i *ngIf="sendInProgress" class="fa fa-spinner fa-spin"></i>
        </button>
      </div>
    </div>
  </div>
</div>
