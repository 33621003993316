import {RikData} from '../rik-data';
import {Booking} from './booking';
import {Campaign} from '../campaign/campaign';

export class MyBooking {
  readonly booking: Booking;
  readonly near: Booking[];
  readonly ical: string | null;

  constructor(data: any) {
    const rikData = new RikData(data);
    this.booking = rikData.getObject('booking', Booking);
    this.near = rikData.getArrayObject('near', Booking);
    this.ical = rikData.getString('ical');
  }
}
