import {Injectable} from '@angular/core';
import {ApiService} from '../services/api.service';
import {BookingDataBody} from './booking-data-body';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BookingDataLoaderService {

  constructor(private api: ApiService) {
  }

  public load(key: string): Observable<BookingDataBody> {
    return this.api.get(`/workshopinfo/v1/my-booking/by-key/${key}`, true);
  }
}
