import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {KlarnaApiService} from './klarna-api.service';
import {KlarnaTypes} from './klarna-types';
import {AbstractControl, FormBuilder, FormGroup} from '@angular/forms';
import {LogService} from '../../core/services/log-service';
import {finalize} from 'rxjs/operators';

declare var Klarna: KlarnaTypes;

export const STATE = {
  REQUEST_EMAIL: 'REQUEST_EMAIL',
  CHOOSE_PAYMENT_OPTION: 'CHOOSE_PAYMENT_OPTION',
  APPROVED: 'APPROVED',
  COMPLETING: 'COMPLETING',
  ERROR: 'ERROR'
};

@Component({
  selector: 'klarna',
  templateUrl: './klarna.component.html',
  styleUrls: ['./klarna.component.scss']
})
export class KlarnaComponent implements OnInit {
  showKlarna = false;
  state = STATE.REQUEST_EMAIL;
  form!: FormGroup;
  email!: string;
  amount!: number;
  paymentCategories: any[] = [];

  @Input()
  bookingEmail!: string;

  @Input()
  bookingUuid!: string;

  @Input()
  globalAppConfigAppUrl: string | null = null;

  @Output()
  approvedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  inProgressChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder,
              private log: LogService,
              private klarnaApiService: KlarnaApiService) {
  }

  ngOnInit(): void {
    this.showKlarna = false;
    this.email = this.bookingEmail;
    this.form = this.fb.group({
      email: [this.email, this.validateEmail.bind(this)]
    });
    this.klarnaApiService.init(this.bookingUuid).subscribe(initResponse => {
      this.log.info('initResponse', initResponse);
      if (initResponse && initResponse.client_token) {
        const response = Klarna.Payments.init({
          client_token: initResponse.client_token
        });
        this.log.info('Payments.init response', response);
        this.amount = initResponse.amount;
        this.paymentCategories = this.initPaymentMethodCategories(initResponse.payment_method_categories);
        this.showKlarna = true;
      }
    });
  }

  validateEmail(control: AbstractControl): any {
    if (!control) {
      return null;
    }
    const value = control.value as string;
    return (value.indexOf('@') > 0 && value.indexOf('.') > 0) ? null : {invalid: true};
  }

  selectEmail(): void {
    this.state = STATE.CHOOSE_PAYMENT_OPTION;
    this.email = this.form.controls.email.value;
  }

  editEmail(): void {
    this.state = STATE.REQUEST_EMAIL;
  }

  initPaymentMethodCategories(categories: any[]): any[] {
    return categories.map(data => {
      const c = Object.assign({}, data);
      c.hidden = true;
      return c;
    });
  }

  select(category: any): void {
    this.paymentCategories.forEach(c => c.hidden = true);
    category.hidden = false;
  }

  authorize(category: any): void {
    this.log.info('authorize', category);
    Klarna.Payments.authorize({
      payment_method_category: category.identifier
    }, {
      purchase_country: 'SE',
      purchase_currency: 'SEK',
      locale: 'sv-SE',
      billing_address: {
        email: this.email
      }
    }, (res: any) => {
      this.log.info('auth res', res);
      if (res.approved) {
        this.handleApproved(res.authorization_token);
      } else {
        this.state = STATE.ERROR;
      }
    });
  }

  handleApproved(authorizationToken: any): void {
    this.inProgressChange.emit(true);
    this.state = STATE.COMPLETING;
    this.klarnaApiService.complete(this.bookingUuid, {
      authorization_token: authorizationToken,
      email: this.email,
      amount: this.amount,
      my_booking: true
    }).pipe(finalize(() => this.inProgressChange.emit(false))
    ).subscribe(resp => {
      this.log.info('handleApproved: done', resp);
      if (resp.error) {
        this.state = STATE.ERROR;
      } else {
        this.state = STATE.APPROVED;
        this.approvedChange.emit(true);
      }
    });
  }

  get stateRequestEmail(): boolean {
    return this.state === STATE.REQUEST_EMAIL;
  }

  get stateChoosePaymentOption(): boolean {
    return this.state === STATE.CHOOSE_PAYMENT_OPTION;
  }

  get stateApproved(): boolean {
    return this.state === STATE.APPROVED;
  }

  get stateCompleting(): boolean {
    return this.state === STATE.COMPLETING;
  }

  get stateError(): boolean {
    return this.state === STATE.ERROR;
  }

  get logoUrl(): string {
    const prefix = this.globalAppConfigAppUrl ? this.globalAppConfigAppUrl : '.';
    return `${prefix}/assets/klarna-pink-100px.png`;
  }
}
