import {RikData} from '../rik-data';
import {ServiceConfiguration} from '../services/service-configuration';
import {Package} from '../services/package';
import {Booking} from '../booking/booking';
import {JobType} from '../constants';
import {TranslateService} from '@ngx-translate/core';

export class Campaign {
  repair_free_wipers: boolean;
  repair_free_washer_fluid: boolean;
  repair_free_glass_treatment: boolean;
  repair_package_enabled: boolean;
  repair_package_price: number | null;
  replace_free_wipers: boolean;
  replace_free_washer_fluid: boolean;
  replace_free_glass_treatment: boolean;
  replace_package_enabled: boolean;
  replace_package_price: number | null;

  constructor(data: any, private base: ServiceConfiguration, private booking: Booking, private translations: TranslateService) {
    const rikData = new RikData(data);
    this.repair_free_wipers = rikData.getBool('repair_free_wipers');
    this.repair_free_washer_fluid = rikData.getBool('repair_free_washer_fluid');
    this.repair_free_glass_treatment = rikData.getBool('repair_free_glass_treatment');
    this.repair_package_enabled = rikData.getBool('repair_package_enabled');
    this.repair_package_price = rikData.getNumber('repair_package_price');

    this.replace_free_wipers = rikData.getBool('replace_free_wipers');
    this.replace_free_washer_fluid = rikData.getBool('replace_free_washer_fluid');
    this.replace_free_glass_treatment = rikData.getBool('replace_free_glass_treatment');
    this.replace_package_enabled = rikData.getBool('replace_package_enabled');
    this.replace_package_price = rikData.getNumber('replace_package_price');
  }

  get serviceConfiguration(): ServiceConfiguration {
    const {services} = this.base;

    return {
      package: this.package(),
      services,
      total: 0
    };
  }

  package(): Package | null {
    const {package: base} = this.base;

    if (!base) {
      return null;
    }

    if (this.booking.type === JobType.Windshield) {
      if (!this.replace_package_enabled) {
        return null;
      }

      if (this.replace_package_price) {
        base.price = this.replace_package_price;
        base.translations.price = this.translations
          .instant('SERVICES.PACKAGE_PRICE_PRICE', {price: base.price, regularPrice: base.regularPrice});
      }

      if (this.replace_free_glass_treatment) {
        base.services.filter(s => s.key === 'GLASS_TREATMENT').forEach(service => {
          service.price = 0;
          service.translations.price = this.translations.instant('SERVICES.GLASS_TREATMENT_CAMPAIGN_FREE');
        });
      }

      if (this.replace_free_washer_fluid) {
        base.services.filter(s => s.key === 'WASHER_FLUID').forEach(service => {
          service.price = 0;
          service.translations.price = this.translations.instant('SERVICES.WASHER_FLUID_CAMPAIGN_FREE');
        });
      }

      if (this.replace_free_wipers) {
        base.services.filter(s => s.key === 'WIPERS').forEach(service => {
          service.price = 0;
          service.translations.price = this.translations.instant('SERVICES.WIPERS_CAMPAIGN_FREE');
        });
      }
    }

    if (this.booking.type === JobType.Repair) {
      if (!this.repair_package_enabled) {
        return null;
      }

      if (this.repair_package_price) {
        base.price = this.repair_package_price;
        base.translations.price = this.translations
          .instant('SERVICES.PACKAGE_PRICE_PRICE', {price: base.price, regularPrice: base.regularPrice});
      }

      if (this.repair_free_glass_treatment) {
        base.services.filter(s => s.key === 'GLASS_TREATMENT').forEach(service => {
          service.price = 0;
          service.translations.price = this.translations.instant('SERVICES.GLASS_TREATMENT_CAMPAIGN_FREE');
        });
      }

      if (this.repair_free_washer_fluid) {
        base.services.filter(s => s.key === 'WASHER_FLUID').forEach(service => {
          service.price = 0;
          service.translations.price = this.translations.instant('SERVICES.WASHER_FLUID_CAMPAIGN_FREE');
        });
      }

      if (this.repair_free_wipers) {
        base.services.filter(s => s.key === 'WIPERS').forEach(service => {
          service.price = 0;
          service.translations.price = this.translations.instant('SERVICES.WIPERS_CAMPAIGN_FREE');
        });
      }
    }

    return base;
  }
}
