export class Emitter<Type> {
  private observers: { (subject: Type): void }[] = [];

  emit(subject: Type): void {
      this.observers.forEach(o => o.apply(this, [subject]));
  }

  observe(callback: { (subject: Type): void}): void {
      this.observers.push(callback);
  }
}
