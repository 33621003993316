import { Component, Input } from '@angular/core';

@Component({
  selector: 'highlighted-heading',
  template: `
    <h3 [innerHtml]="heading | translate"></h3>
    <div class="images">
      <div class="image"></div>
      <div class="image"></div>
      <div class="image"></div>
    </div>
  `,
  host: { class: 'section-heading' },
})
export class HighlightedHeadingComponent {
  @Input()
  heading: string = 'Title';
}
