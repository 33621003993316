import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MyBookingPageComponent} from './my-booking-page/my-booking-page.component';
import {BookingResolver} from '../../domain/booking/booking-resolver';
import {MY_BOOKING_ROUTES} from './my-booking-routes';
import {APP_ROUTES} from '../../app-routes';
import {LandingPageComponent} from './landing-page/landing-page.component';
import {PaymentPageComponent} from './payment-page/payment-page.component';
import {WorkshopDetailsPageComponent} from './workshop-details-page/workshop-details-page.component';
import {RebookPageComponent} from './rebook-page/rebook-page.component';
import {NotFoundPageComponent} from './not-found-page/not-found-page.component';
import {AgreementsPageComponent} from './agreements-page/agreements-page.component';
import {
  AgreementsCourtesyVehicleExtraPageComponent
} from './agreements-page/pages/agreements-courtesy-vehicle-extra-page/agreements-courtesy-vehicle-extra-page.component';
import {
  AgreementsSignSuccessPageComponent
} from './agreements-page/pages/agreements-sign-success-page/agreements-sign-success-page.component';
import {AgreementsSignErrorPageComponent} from './agreements-page/pages/agreements-sign-error-page/agreements-sign-error-page.component';
import {AgreementsSignRejectPageComponent} from './agreements-page/pages/agreements-sign-reject-page/agreements-sign-reject-page.component';
import {
  AgreementsSignProcessingPageComponent
} from './agreements-page/pages/agreements-sign-processing-page/agreements-sign-processing-page.component';
import {BookingLoggingResolver} from '../../domain/booking/booking-logging-resolver';
import {BookingDataResolver} from '../../core/booking-data/booking-data.resolver';

const routes: Routes = [
  {
    path: MY_BOOKING_ROUTES.NOT_FOUND_PAGE,
    component: NotFoundPageComponent,
  },
  {
    path: MY_BOOKING_ROUTES.LANDING_PAGE,
    component: LandingPageComponent,
    resolve: {myBooking: BookingResolver, bookingData: BookingDataResolver},
    children: [
      {
        path: '',
        component: MyBookingPageComponent,
        resolve: {myBooking: BookingLoggingResolver},
        data: {animationDepth: 1}
      },
      {
        path: MY_BOOKING_ROUTES.AGREEMENTS_PAGE,
        component: AgreementsPageComponent,
        data: {animationDepth: 2}
      },
      {
        path: MY_BOOKING_ROUTES.AGREEMENTS_COURTESY_VEHICLE_EXTRA_PAGE,
        component: AgreementsCourtesyVehicleExtraPageComponent,
        data: {animationDepth: 3}
      },
      {
        path: MY_BOOKING_ROUTES.AGREEMENTS_SIGN_SUCCESS,
        component: AgreementsSignSuccessPageComponent
      },
      {
        path: MY_BOOKING_ROUTES.AGREEMENTS_SIGN_PROCESSING,
        component: AgreementsSignProcessingPageComponent
      },
      {
        path: MY_BOOKING_ROUTES.AGREEMENTS_SIGN_ERROR,
        component: AgreementsSignErrorPageComponent
      },
      {
        path: MY_BOOKING_ROUTES.AGREEMENTS_SIGN_REJECT,
        component: AgreementsSignRejectPageComponent
      },
      {
        path: MY_BOOKING_ROUTES.PAYMENT,
        component: PaymentPageComponent,
        data: {animationDepth: 2}
      },
      {
        path: MY_BOOKING_ROUTES.DETAILS,
        component: WorkshopDetailsPageComponent
      },
      {
        path: MY_BOOKING_ROUTES.REBOOK,
        component: RebookPageComponent,
      },
      {
        path: '**',
        redirectTo: APP_ROUTES.ERROR_PAGE
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MyBookingRoutingModule {
}
