import {Injectable} from '@angular/core';

export interface InputMaskReturn {
  mask: () => RegExp[];
  guide: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class InputMaskService {
  get phoneMask(): InputMaskReturn {
    return {
      mask: () => {
        // Phone numbers can optionally start with '+'
        return [/[+\d]/].concat(InputMaskService.createDigitRegexpArray(14));
      },
      guide: false
    };
  }

  orgNoMask(countryCode: string): InputMaskReturn {
    return {
      mask: () => {
        if (countryCode.toUpperCase() === 'NO') {
          return InputMaskService.createDigitRegexpArray(11);
        }
        return InputMaskService.createDigitRegexpArray(10);
      },
      guide: false
    };
  }

  get postalCodeMask(): InputMaskReturn {
    return {
      mask: () => InputMaskService.createDigitRegexpArray(6),
      guide: false
    };
  }

  get erpCustomerNoMask(): InputMaskReturn {
    return {
      mask: () => InputMaskService.createAlphanumRegexpArray(1).concat(InputMaskService.createDigitRegexpArray(5)),
      guide: false
    };
  }

  get regNoMask(): InputMaskReturn {
    return {
      mask: () => InputMaskService.createAlphanumRegexpArray(10),
      guide: false
    };
  }

  get percentMask(): InputMaskReturn {
    return {
      mask: () => InputMaskService.createDigitRegexpArray(2),
      guide: false
    };
  }

  get excessMask(): InputMaskReturn {
    return {
      mask: () => InputMaskService.createDigitRegexpArray(6),
      guide: false
    };
  }

  get sellerMask(): InputMaskReturn {
    return {
      mask: () => InputMaskService.createDigitRegexpArray(6),
      guide: false
    };
  }

  get mileageMask(): InputMaskReturn {
    return {
      mask: () => InputMaskService.createDigitRegexpArray(10),
      guide: false
    };
  }

  get bookingNoMask(): InputMaskReturn {
    return {
      mask: () => InputMaskService.createDigitRegexpArray(10),
      guide: false
    };
  }

  get itemNoMask(): InputMaskReturn {
    return {
      mask: () => InputMaskService.createItemNoRegexpArray(35),
      guide: false
    };
  }

  private static createDigitRegexpArray(len: any): RegExp[] {
    return Array.from(new Array(len), (val, index) => /\d/);
  }

  private static createAlphanumRegexpArray(len: any): RegExp[] {
    return Array.from(new Array(len), (val, index) => /[\da-zåäö]/i);
  }

  private static createItemNoRegexpArray(len: any): RegExp[] {
    return Array.from(new Array(len), (val, index) => index === 0 ? /[\da-zåäö]/i : /[\da-zåäö\-. ]/i);
  }
}
