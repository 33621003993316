import {AbstractControl, FormControl, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {startWith} from 'rxjs/operators';

export class ReactiveFormHelper {
  static get = (form: FormGroup, name: string): AbstractControl => getCtrl(form, name);

  static getValue = (form: FormGroup, name: string): unknown => getCtrl(form, name).value;

  static setValue = (form: FormGroup, name: string, value: unknown, shouldUpdate: boolean = true) => {
    const ctrl = getCtrl(form, name);

    if (shouldUpdate) {
      ctrl.setValue(value);
    }
  }

  static enableOrDisableCtrl = (form: FormGroup, name: string, disable: boolean) => {
    const ctrl = getCtrl(form, name);

    disable ? ctrl.disable() : ctrl.enable();
  }

  static getObsWithInitial = (form: FormGroup, name: string): Observable<unknown> => ReactiveFormHelper
    .get(form, name).valueChanges
    .pipe(startWith(ReactiveFormHelper.getValue(form, name)))

  static validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}

const getCtrl = (form: FormGroup, name: string): AbstractControl => {
  if (!form || !name) {
    throw Error('Form or name is missing form:' + form + ' name:' + name);
  }
  const ctrl = form.get(name);
  if (!ctrl) {
    throw Error('Ctrl is not available. form:' + form + ' name: ' + name);
  }
  return ctrl;
};
