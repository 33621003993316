import {Component, OnInit} from '@angular/core';
import {map} from 'rxjs/operators';
import {ActivatedRoute, Router, RouterOutlet} from '@angular/router';
import {BookingStoreService} from '../../../domain/booking/booking-store.service';
import {slideInOutAnimationV2} from '../../../core/services/rb-animations';
import {BookingDataService} from '../../../core/booking-data/booking-data.service';
import {CampaignService} from '../../../domain/campaign/campaign.service';
import {BookingScopedService} from '../../../domain/booking/booking-scoped.service';

@Component({
  templateUrl: './landing-page.component.html',
  animations: [slideInOutAnimationV2],
  providers: [BookingScopedService, BookingDataService, CampaignService]
})
export class LandingPageComponent implements OnInit {

  constructor(private router: Router,
              private route: ActivatedRoute,
              private bookingStoreService: BookingStoreService,
              private data: BookingDataService) {
  }

  ngOnInit(): void {
    this.data.data = this.route.snapshot.data.bookingData;
    this.route.data.pipe(map((data: any) => data.myBooking)).subscribe(myBooking => {
      this.bookingStoreService.set(myBooking);
    });
  }

  prepareRoute(outlet: RouterOutlet): any {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animationDepth;
  }
}
